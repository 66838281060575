:root {
  --color-primary: rgb(139, 0, 0);
  --color-primary-rgb: 139, 0, 0;

  --color-heading-text: #212121;
  --color-body-text: #666666;
  --color-disabled-text: #9e9e9e;

  --color-bg-primary: #fff;
  --color-bg-secondary: #fafafa;
  --color-bg-tertiary: #f4f4f4;

  --color-border-primary: #e6e6e6;
  --color-border-secondary: #f2f2f2;

  --color-shadow-primary: rgba(0, 0, 0, 0.24);
  --color-shadow-secondary: rgba(0, 0, 0, 0.16);

  --transition-ease-primary: cubic-bezier(0.645, 0.045, 0.355, 1);
  --transition-primary: all 160ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
