*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: none;

  font-size: inherit;
  font-family: inherit;

  color: currentColor;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
}

body {
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0.3px;

  color: var(--color-body-text);
  background-color: var(--color-bg-tertiary);

  overflow: hidden auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  color: var(--color-heading-text);
  line-height: 1.25;
}

input,
button,
label {
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
  color: var(--color-primary);
}

a:hover {
  text-decoration: underline;
}

svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

::selection {
  color: var(--color-bg-primary);
  background-color: var(--color-primary);
}
